<template>
	<div :class="['dropdown',{'dropdown--open' : open},]">
		<slot></slot>
	</div>
</template>

<script>
export default {
	props: {
		open: Boolean,
	},
	data(){
		return{
			
		}
	},
	methods: {
	},
}

</script>

<style lang="scss" scoped>
.dropdown{
	background: var(--dropdown-background);
	border: var(--dropdown-border);
	position: absolute;
	z-index: 10;
	top: var(--dropdown-top);
	right: var(--dropdown-right);
	left: var(--dropdown-left);
	padding: var(--dropdown-padding);
	border-radius: var(--dropdown-border-radius);
	opacity: var(--dropdown-opacity);
	transition:  var(--dropdown-transition);
	box-shadow: var(--dropdown-box-shadow);
	width: var(--dropdown-width);
	max-height: 0;
	overflow: hidden;
}
.dropdown--open{
	opacity: var(--dropdown-open-opacity);
	top: var(--dropdown-open-top);
	max-height: 1000px;
}
</style>

<!-- Used in : DS, SD -->